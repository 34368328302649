/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

/* THEME FROM MATERIAL */
@import "global-custom-colors-material";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

/* Quill editor */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

/** Tailwind **/
@tailwind base;
@tailwind components;
@tailwind utilities;

/* FONTS */

// SOURCE SERIF PRO

//REGULAR (400)
@font-face {
  font-family: 'Source Serif Pro';
  font-weight: normal;
  font-style: normal;
  src: url("./fonts/sourceSerifPro/SourceSerifPro-Regular.ttf");
}

//SEMI-BOLD (600)
@font-face {
  font-family: 'Source Serif Pro';
  font-weight: 600;
  font-style: normal;
  src: url("./fonts/sourceSerifPro/SourceSerifPro-SemiBold.ttf");
}

//BOLD (700)
@font-face {
  font-family: 'Source Serif Pro';
  font-weight: 700;
  font-style: normal;
  src: url("./fonts/sourceSerifPro/SourceSerifPro-Bold.ttf");
}

// WORK SANS

//REGULAR (400)
@font-face {
  font-family: 'Work Sans';
  font-weight: normal;
  font-style: normal;
  src: url("./fonts/workSans/WorkSans-Regular.ttf");
}

//MEDIUM (500)
@font-face {
  font-family: 'Work Sans';
  font-weight: 500;
  font-style: normal;
  src: url("./fonts/workSans/WorkSans-Medium.ttf");
}

//SEMI-BOLD (600)
@font-face {
  font-family: 'Work Sans';
  font-weight: 600;
  font-style: normal;
  src: url("./fonts/workSans/WorkSans-SemiBold.ttf");
}

//BOLD (700)
@font-face {
  font-family: 'Work Sans';
  font-weight: 700;
  font-style: normal;
  src: url("./fonts/workSans/WorkSans-Bold.ttf");
}

//BOLD ITALIC (700)
@font-face {
  font-family: 'Work Sans';
  font-weight: 700;
  font-style: italic;
  src: url("./fonts/workSans/WorkSans-BoldItalic.ttf");
}

html {
  @apply text-primary-900
}

//text font
*:not(ion-icon) {
  font-family: 'Work Sans', sans-serif;
  font-size: 16px;
  font-weight: normal;
}

strong {
  font-weight: bold !important;
}

//Line-height
p {
  line-height: 24px;
}

//Title hierarchy
h1 {
  font-family: 'Work Sans', sans-serif;
  font-size: 2em;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
}

h2 {
  font-family: 'Work Sans', sans-serif;
  font-size: 30px;
  font-weight: 700;
}

h3 {
  font-family: 'Work Sans', sans-serif;
  font-size: 24px;
  font-weight: 700;
}

h4 {
  font-family: 'Work Sans', sans-serif;
  font-size: 21px;
  font-weight: 700;
}

h5 {
  font-family: 'Work Sans', sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin: 0
}

/* BUTTONS */


.btn-edit {
  --background: rgba(var(--ion-color-primary-rgb),0.1) !important;
  --color: var(--ion-color-primary) !important;
  --border-radius: 8px !important;
  ion-icon {
    font-size: 24px !important;
  }
  --background-hover: rgba(var(--ion-color-primary-rgb),0.1) !important;
}

.btn-disable {
  --background: rgba(var(--ion-color-danger-rgb),0.2) !important;
  --color: var(--ion-color-danger) !important;
  --border-radius: 8px !important;
  ion-icon {
    font-size: 24px !important;
  }
}

.btn-enable {
  --background: rgba(var(--ion-color-success-rgb),0.2) !important;
  --color: var(--ion-color-success) !important;
  --border-radius: 8px !important;
  ion-icon {
    font-size: 24px !important;
  }
}

.btn-toggle-contact {
  --background: rgba(var(--ion-color-medium-rgb),0.1) !important;
  --color: var(--ion-color-medium) !important;
  --border-radius: 8px !important;
  font-size: 16px !important;
  ion-icon {
    font-size: 24px !important;
  }
}

//button copy file in backoffice
.btn-purple {
  --background: rgba(var(--ion-color-primary-rgb),0.1) !important;
  --color: var(--ion-color-primary) !important;
  --border-radius: 8px !important;
  --background-hover: rgba(var(--ion-color-primary-rgb),0.1) !important;
  ion-icon {
    font-size: 16px !important;
  }
}

.btn-delete {
  --background: rgba(var(--ion-color-danger-rgb),0.1) !important;
  --color: var(--ion-color-danger) !important;
  --border-radius: 8px !important;
  --background-hover: rgba(var(--ion-color-danger-rgb),0.1) !important;
  ion-icon {
    font-size: 16px !important;
  }
}


//ITEM STATUS
.item-status {
  color: (var(--ion-color-medium));
  font-size: 11px;
  font-weight: 500;
}

// Mat Paginator (align left)
.mat-mdc-paginator-container {
  flex-direction: row-reverse;

  // Because we used row-reverse, the order of the fields is not going to be correct inside mat-paginator.
  // We need to fix it by changing the order of the fields.
  .mat-mdc-paginator-range-label {
    order: 1;
  }
}

.mdc-notched-outline__notch {
  border-right: none !important;
}

/** CLASS **/
.btn-lg{
  min-height: 56px;
}

.btn-loading{
  @keyframes spinner {
    to {transform: rotate(360deg);}
  }

  // color: transparent!important;

  &:before{
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #000000;
    animation: spinner .8s linear infinite;
  }
}

.link-in-translation a{
  text-decoration: underline;
  font-weight: bold;
}

b{
  font-size: inherit;
  font-weight: bold
}

@layer components {
  .fm-table-custom {
    @apply w-full table-auto border-separate border-spacing-y-2 text-black;
  }

  .fm-table-custom thead{
    @apply w-full text-left;
  }

  .fm-table-custom th, td{
    @apply p-3;
  }

  .fm-table-custom tbody td{
    @apply border border-gray-200 border-collapse;
  }

  .fm-table-custom tbody td img{
    @apply h-5 w-5 m-auto;
  }

  .bg-card-custom {
    background-image: url('./background/bg-card.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .fm-article-header {
    @apply flex flex-col gap-4 mb-4;
  }

  .fm-article-modal-header {
    @apply flex items-center border-b border-solid border-gray-200 p-4 rounded-t-xl
  }

  .fm-course-modal {
    @apply flex flex-col bg-[#FBFBFB] h-screen;
  }

  .fm-course-modal-title {
    font-size: 20px;
    line-height: 24px;
    @apply font-medium;
  }

  .fm-course-modal-content {
    @apply flex flex-col gap-3 px-4 py-3
  }

  .fm-course-modal-card {
    @apply w-full bg-white border border-solid border-[#EEEEEE] rounded-xl;
  }

  .fm-course-modal-card-header {
    @apply flex gap-1 items-center border-b border-solid border-gray-200 p-4 rounded-t-xl;
  }

  .fm-course-modal-card-header-title {
    font-size: 17px;
    line-height: 24px;
    @apply font-medium;
  }

  .fm-course-modal-card-content{
    @apply m-4;
  }

  .btn-full{
    @apply bg-primary rounded-full text-white text-center w-full py-3 flex justify-center items-center;
  }

  .btn-small{
    @apply py-1.5
  }

  .btn-danger{
    @apply bg-red-500 text-white
  }

  .btn-disabled {
    @apply opacity-30;
  }
}
.ql-editor{
  padding: 0!important;
}

ion-modal {
  --ion-background-color: white;
}
